import React from 'react';
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';

const CPDevs = () => {
  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        justify={'center'}
        direction={'column'}
      >
        <Text
          fontSize={'3xl'}
          fontFamily={'heading'}
          color={useColorModeValue('gray.800', 'white')}
        >
          Tarkvaraarendus
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} py={10}>
          <ServiceBox
            title="Veebiarendus"
            description="Tipptasemel veebiarendusteenused, kasutades React.js-i, dünaamilise ja interaktiivse kasutajaliidese loomiseks."
          />
          <ServiceBox
            title="C# Arendus"
            description="Turvaliste ja skaleeritavate API-de loomine, mis integreeruvad sujuvalt teie süsteemidega."
          />
          <ServiceBox
            title="Python"
            description="Lihtsatest keerukateni API-de loomine, kasutades Flaski, et tagada paindlikkus ja skaleeruvus."
          />
          <ServiceBox
            title="Masinõppe ja Suurandmete Analüüs"
            description="Andmete töötlemine, ennustavate mudelite loomine ja suurandmete analüüs, kasutades tööriistu nagu Pandas ja Scikit-learn."
          />
          <ServiceBox
            title="NoSQL Andmebaaside Päringud ja Seadistus - Elasticsearch"
            description="Kiire ja paindlik andmete indekseerimine ja päringute teostamine Elasticsearchi abil."
          />
          <ServiceBox
            title="Tehisintellekti Juurutamine"
            description="Tehisintellekti lahenduste pakkumine, mis vastavad teie organisatsiooni vajadustele. AutoGPT ja ChatGPT lahenduste juurutamine, et automatiseerida ja parendada näiteks klienditeenindust."
          />
        </SimpleGrid>
      </Flex>
    </Box>
  );
};

const ServiceBox = ({ title, description }) => {
  return (
    <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md">
      <Text fontSize="xl" fontWeight="semibold">{title}</Text>
      <Text mt={4}>{description}</Text>
    </Box>
  );
};

export default CPDevs;
