import {
    Box,
    Button,
    Container,
    SimpleGrid,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    Link,
    CardBody,
    CardHeader,
    Card,
    Image,
    Divider,CardFooter,ButtonGroup,
    useColorModeValue,
  } from '@chakra-ui/react'
  import { ReactElement } from 'react'  
import newsimg from '../plaaster.png'; 
import { NavLink, useNavigate } from 'react-router-dom';


  const MNTestCard = (newsId) => {

    const navigate = useNavigate();
  
    const navigateToNews = (newsId) => {
      navigate(`/uudis/${newsId}`);
    };
    
    return (
      <Card 
      maxW='lg'
      shadow={'dark-lg'}
     
      
      
      >
  <CardBody>
    <Image
      src={newsimg}
      alt='Green double couch with wooden legs'
      borderRadius='lg'
      shadow={'dark-lg'}
      onClick={navigateToNews}
      
    />
    <Stack mt='6' spacing='3'>
      <Heading size='md'>Meditsiini uudis </Heading>
      <Text>
      Laulupeo meditsiiniteenistuse juht: tantsuplatsil on vaja plaastreid ja peale puhumist, 
      lauluväljakul tuleb ette nõrkushooge. Artiklis räägitakse Erik Vellerammist, kes on kaheksa aastat olnud laulu- ja tantsupidude meditsiiniteenistuse juht. XIII noorte ... 
      
      </Text>
      
    </Stack>
  </CardBody>
  <Divider />
  <CardFooter>
    <Box spacing='2'>
    <Text variant='solid' colorScheme='green'>
        Autor
      </Text>
      <Text variant='ghost' colorScheme='green'>
        2024-01-23
      </Text>
    </Box>
    
  </CardFooter>
</Card>
    )
  }
  
  
  const MNNews = () => {
    return (
      <div marginTop={5}>
        <Heading size='md' color={'white'} marginTop={5}>UUDISTE ARTIKLID </Heading>
        <SimpleGrid marginLeft={10} 
          marginTop={8}
          spacing={8} 
          templateColumns='repeat(auto-fill, minmax(220px, 1fr))'>
          
          <MNTestCard newsId={"1"}/>
          <MNTestCard newsId={"2"}/>
          <MNTestCard newsId={"3"}/>
        </SimpleGrid>
      </div>

      
    )
  }
  
  export default MNNews;