import { Grid, GridItem } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

// components
import Footer from "../components/Footer"
import CPNavBar from "../components/CPNavBar"

export default function RootLayout() {
  return (
    <Grid templateColumns="repeat(6, 1fr)" bg="gray.50"
    direction="column"
      align="center"
      justify="flex-start"
      bgGradient="linear(to-b, green.400, gray.600)"
      minH="100vh"
    >
      

      {/* main content & navbar */}
      <GridItem
        as="main"
        colSpan={{ base: 8, lg: 4, xl: 6 }} 
        p="22px"
      >
        <CPNavBar />
        <Outlet />
        <Footer />
      </GridItem>
      
    </Grid>
  )
}
