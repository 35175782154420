

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  VStack,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'

export default function WithSubnavigation() {
    const { isOpen, onToggle } = useDisclosure()
  
    return (
      <Box>
        <Flex
          bgGradient="linear(to-r, green.200, blue.100)"
          //bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}
          justify={'space-between'} // Elementide vahelise ruumi säilitamine
          rounded={10}
          shadow={'dark-lg'}
        >
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
            display={{ base: 'flex', md: 'none' }} // Nupp on nähtav ainult mobiilivaates
          />
          <VStack // Kasutage VStack komponenti vertikaalseks paigutuseks
    textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
    flexGrow={{ base: 1, md: 0 }}
  >
    <Text
      as="h1"
      fontSize="4xl"
      fontFamily={'heading'}
      color={useColorModeValue('gray.800', 'white')}
      mt={4}
      marginLeft={4}
      textShadow="3px 3px white"
    >
      Meditsiini Uudised
    </Text>
    <Text
      as="h3"
      fontSize="1xl"
      fontFamily={'heading'}
      color={useColorModeValue('gray.900', 'white')}
     
      alignSelf="flex-end " // Liiguta see tekst paremale
      
    >
      Ole terve, hoia kätt elu pulsil ...
    </Text>
  </VStack>
  
          <Flex
            display={{ base: 'none', md: 'flex' }} // Menüü on nähtav ainult md suuruses ja suuremates
            align={'center'}
            ml={'auto'} // Menüüelementide automaatne paremale liigutamine
          >
            <DesktopNav />
          </Flex>
        </Flex>
  
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    )
  }
  
  // DesktopNav, DesktopSubNav, MobileNav ja MobileNavItem komponendid jäävad samaks
  
  
  // Ülejäänud komponendid (DesktopNav, DesktopSubNav, MobileNav, MobileNavItem) jäävad samaks
  

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction={'row'} spacing={5}
    align={'right'}
    justify={'flex-end'}
    >
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                as="a"
                p={4}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel } ) => {
  return (
    <Box
      as="a"
      href={href}
      role={'group'}
      display={'block'}
      p={4}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('gray.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'gray.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'gray.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? '#'}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}>
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={4}
            h={4}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}


const NAV_ITEMS = [
    {
      label: 'Avaleht',
      href: '/',
    },
    {
      label: 'Uudised',
      href: 'uudised',
    },
    {
      label: 'Teenused',
      children: [
        {
          label: 'Konsultatsioonid',
          
          href: '/consulting',
        },
        {
          label: 'Tarkvaraarendus',
          
          href: '/devs',
        },
        {
            label: 'Tõlked',
            
            href: 'translate',
          },
      ],
    },
    
    {
        label: 'Kontakt',
        href: 'contact',
      },
    
  ];
  
  