import * as React from "react"

import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  Route, 
  RouterProvider 
} from 'react-router-dom';

// layouts and pages
import RootLayout from './layouts/RootLayout'
import CPServices from "./components/CPServices";
import CPConsulting from "./components/CPConsulting";
import CPDevs from "./components/CPDevs";
import CPContact from "./components/CPContact";
import CPTranslate from "./components/CPTranslate";
import MNNews from "./components/MNNews";
import MNHero from "./components/MNHero";
import MNArticle from "./components/MNArticle";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<MNHero />}></Route>
      <Route path="services" element={< CPServices/>}></Route>
      <Route path="consulting" element={< CPConsulting/>}></Route>
      <Route path="devs" element={< CPDevs/>}></Route>
      <Route path="contact" element={< CPContact/>}></Route>
      <Route path="translate" element={< CPTranslate/>}></Route>
      <Route path="uudised" element={< MNNews/>}></Route>
      <Route path="uudis/:id" element={<MNArticle />} />
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App

