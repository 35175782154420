import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    IconButton,
    Button,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    
    Table
  } from '@chakra-ui/react';
  

  import React, { useState } from 'react';

  import backimg from '../backimg.png'; 
  
  export default function CPContact() {

    // State vormi andmete jaoks
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    // Defineerime Unicode'i sümbolid
    const phoneSymbol = "\u260E"; // Telefon
    const emailSymbol = "\u2709"; // Email
    const locationSymbol = "\u2691"; // Asukoht

    // Funktsioon vormi andmete käsitsemiseks
    const handleSubmit = async (event) => {
      event.preventDefault();
    
      // Formi andmete kokku kogumine
      const formData = {
        name: "Nimi",
        email: "kasutaja@email.com",
        message: "See on test sõnum."
      };
    
      // Saada andmed serverisse fetch API abil
      try {
        const response = await fetch('https://cyberplanet.tech/send-email.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData), // konverteeri JavaScripti objekt JSON stringiks
        });
    
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log("Sõnum saadetud: ", jsonResponse);
        } else {
          throw new Error('Midagi läks valesti!');
        }
      } catch (error) {
        console.error("E-kirja saatmine ebaõnnestus: ", error);
      }
    };
    

    return (
      <Container maxW="full" mt={0} centerContent overflow="hidden" bg="gray.100">
        <Flex>
          <Box
           style={{
            backgroundImage: `url(${backimg})`,
            backgroundSize: '', // See tagab, et pilt katab kogu kasti
            backgroundPosition: 'center', // See keskendab pildi
            width: '100vw',
            height: '100vh'
          }}
            bg={'gray.100'}
            color={'gray.200'}
            borderRadius="md"
            m={{ sm: 4, md: 16, lg: 10 }}
            p={{ sm: 5, md: 5, lg: 16 }}>
            <Box p={4}>
              <Wrap spacing={{ base: 16, sm: 8, md: 9, lg: 18 }}>
                <WrapItem>
                  <Box>
                    
                    <Heading>Hoia end ja teisi,</Heading>
                    <Heading>ole kursis uudistega,</Heading>
                    <Heading>taba hetke ...</Heading>
                    
                    
                    
                    <HStack
                      mt={{ lg: 10, md: 10 }}
                      spacing={5}
                      px={5}
                      alignItems="flex-start">
                      
                      
                     
                    </HStack>
                  </Box>
                </WrapItem>
                
              </Wrap>
            </Box>
          </Box>
        </Flex>
      </Container>
    );
  }
  